'use strict'

###*
 # @ngdoc object
 # @name mundolive.service:LiveSubscriptionService

 # @description

###
class LiveSubscriptionService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $q
    $rootScope
    $document
    $window
    $interval
    MundoSocket
    ReportConfigurationManager
    LiveSubscriptionManager
    Restangular
  ) ->

    @authenticationSuccessEventName = 'mundo:authentication:success'
    @authListener

    @requireUser = () =>
      return new Promise (resolve, reject) =>
        if $rootScope.user?
          resolve true
        else
          @authListener = $rootScope.$on @authenticationSuccessEventName, () ->
            resolve true

    @requireSubscription = () =>
      return new Promise (resolve, reject) =>
        if @storage.exists "subscription"
          subscription = @storage.get "subscription"

          if subscription? && $rootScope.user.activeUserContext.id is subscription.userContext.id
            resolve subscription
          else
            @createNewSubscription().then (subscription) =>
              @storage.set "subscription", subscription
              resolve subscription
            # There ain't no subscription yet. Create a new one.

    @createNewSubscription = () ->
      return new Promise (resolve, reject) ->
        ReportConfigurationManager.post
          label: "Subscription-config"
          source: "MongoUnitStatus"
        .then (configuration) ->
          LiveSubscriptionManager.post
            enabled: true
            reportConfiguration: configuration._id
          .then (newSubscription) ->
            resolve newSubscription

    @createNewSubscriptionFromConfiguration = (configuration) ->
      return new Promise (resolve, reject) ->
        LiveSubscriptionManager.post
          enabled: true
          reportConfiguration: configuration
        .then (newSubscription) ->
          resolve newSubscription

    @extendSubscription = (subscription) =>
      return new Promise (resolve, reject) =>
        # If the subscription is about to expire within 6 hours, extend it.
        expireDate = moment(subscription.expiresAt).subtract(6, 'hours')

        if moment() >= expireDate
          # Check if we need to extend the expiry date some time.
          Restangular.all 'services/subscriptions/extend'
            .customPOST
              subscription: subscription._id
            .then(
              (sub) =>
                @storage.set 'subscription', sub
                resolve sub
              () =>
                # There was probably an error. Let's just create a new subscription and configuration ?
                @createNewSubscription().then (sub) =>
                  @storage.set 'subscription', sub
                  resolve sub
            )
        else
          resolve subscription

    @enable = () =>
      return new Promise (resolve, reject) =>
        # This only resolves when there actually is a logged in user.
        @requireUser().then () =>
          if @authListener?
            @authListener()
          @requireSubscription().then (subscription) =>
            @extendSubscription(subscription).then (subscription) =>
              @startSocket subscription
              resolve subscription.reportConfiguration

    @disable = () =>
      @requireSubscription().then (subscription) =>
        @stopSocket(subscription)

    @getConfiguration = () =>
      return new Promise (resolve, reject) =>
        @requireSubscription().then (subscription) ->
          ReportConfigurationManager.one subscription.reportConfiguration
            .get()
            .then (configuration) ->
              resolve configuration

    @startSocket = (subscription) =>
      MundoSocket.toggleSubscriptionConsumer true, [subscription._id]
      # Check every 5 minutes of subscription needs renewal
      @extendInterval = $interval () =>
        @extendSubscription subscription
      , 300000

    @stopSocket = (subscription) ->
      MundoSocket.toggleSubscriptionConsumer false, [subscription._id]

    @renewSubscription = (configuration) =>
      return new Promise (resolve, reject) =>
        @requireSubscription().then (subscription) =>
          @stopSocket subscription
          LiveSubscriptionManager.one subscription._id
            .remove()
            .then () =>
              @storage.remove 'subscription'
              @createNewSubscriptionFromConfiguration(configuration).then (sub) =>
                @storage.set 'subscription', sub
                @enable().then (configuration) ->
                  resolve configuration

    # Storage stuff
    @storage =
      set: (key, item, stringify = true) ->
        if stringify
          item = JSON.stringify item
        localStorage.setItem key, item

      get: (key, parse = true) ->
        item = localStorage.getItem key
        if item?
          if parse
            return JSON.parse item
          else
            return item
        else
          item
      remove: (key) ->
        localStorage.removeItem key
      exists: (key) ->
        return !(localStorage.getItem key == null)

angular
  .module('mundoLive')
  .service 'LiveSubscriptionService', LiveSubscriptionService
